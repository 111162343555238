import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../reducers';
import thunkMiddleware from 'redux-thunk'

import firebase from 'firebase/app'
import firebaseConfig from "../fribaseConfig";
import 'firebase/auth';

import { url, prefixImageUrl } from "../constant";

if(!firebase.apps.length){
    firebase.initializeApp(firebaseConfig);
}

// Temporary arrays for filters
var cuisines = []
var area = [];
var city = [];
var fT = []; // Features & tags
var sF = []; // Special foods

// For restaurent pagination
var data = {}
var limit = 10
var skip = 0
var changed = 0

// For Promotion pagination
var promoLimit = 20
var promoSkip = 0

const shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
}

export let filterGetData = payload  => async (dispatch, getState) =>{
    if(payload != 'first' && payload != 'loadMore'){
        dispatch({ type: 'loading', payload: true })

        if(payload.cuisines){
            cuisines = [];
            dispatch({ type: 'cuisineSelected', payload: payload.key })

            if(payload.key){
                if(payload.key.length > 0){
                    payload.key.map((item)=>{
                        cuisines.push(item.label)
                    })
                }
            }
        } else if(payload.ft){
            fT = []
            dispatch({ type: 'ftSelected', payload: payload.key })
            
            if(payload.key){
                if(payload.key.length > 0){
                    payload.key.map((item) => {
                        fT.push(item.label)
                    })
                }
            }
        } else if(payload.city){
            city = []
            dispatch({ type: 'citySelected', payload: payload.key })
            
            if(payload.key){
                if(payload.key.length > 0){
                    payload.key.map((item) => {
                        city.push(item.label)
                    })
                }
            }
        } else if(payload.sf){
            sF = []
            dispatch({ type: 'sfSelected', payload: payload.key })

            if(payload.key){
                if(payload.key.length > 0){
                    payload.key.map((item) => {
                        sF.push(item.label)
                    })
                }
            }
        } else {
            area = []
            dispatch({ type: 'regionSelected', payload: payload.value })
            if(payload.value){
                if(payload.value.length > 0){
                    payload.value.map((item)=>{
                        area.push(item.label)
                    })
                }
            }
        }

        if(area.length > 0 && cuisines.length > 0 && fT.length > 0 && sF.length > 0 && city.length > 0 ){
            changed = 0
            changed = changed + 1
            skip = 0;

            data = {
                "cuisines": { "$in": cuisines },
                "area": { "$in": area },
                "featuresTags": { "$in": fT },
                "sf": { "$in": sF },
                "city": { "$in": city }
            }
        }
        else {
            skip = 0;
            changed = 0
            changed = changed + 1

            if (area.length > 0) {
                data = { "area": { "$in": area }}
            } else if (cuisines.length > 0) {
                data = { "cuisines": { "$in": cuisines }}
            } else if (fT.length > 0) {
                data = {"featuresTags": { "$in": fT }}
            } else if (sF.length > 0) {
                data = {"sf": { "$in": sF }}
            } else if (city.length > 0) {
                data = {"city": { "$in": city }}
            } else {
                data = {}
            }
        }
    }
    else if(payload == "loadMore"){
        if(area.length > 0 | cuisines.length > 0 | fT.length > 0 | sF.length > 0 | city.length > 0){
            changed = changed + 1
        } else {
            changed = 0
        }
    }
    else {
        data = {}
    }

    await fetch(url+'/getrestaurants', {
        method: "POST",
        body: JSON.stringify({"data": data, "skip": skip, "limit": limit})
    })
    .then(res =>res.json())
    .then((data)=> {
        skip = data['response'].length + skip                
        data['response'].map((card)=>{
            if(String(card.featured_image).length === 32) {                    
                card.featured_image = prefixImageUrl+card.featured_image+'.jpg';
            } else if (String(card.featured_image).length === 36) {                
                card.featured_image =  prefixImageUrl+card.featured_image
            }
        })
        dispatch({ type: 'loading', payload: false })
        dispatch({ type: 'fetch_data', resLength: data['length'], payload: shuffle(data['response']), fromCat: changed == 1 ? true : false, disableButton: data['length'] <= 15 ? true : data['response'].length == 0 ? true : false})        
    })
    .catch((er)=>{
        console.log("Error", er)
    })
}

export async function fetchPromoData (dispatch, getState){
    await fetch(url+'/getAllMainPromos', {
        method: "POST",
        body: JSON.stringify({"skip": promoSkip, "limit": promoLimit})
    })
    .then((res)=>res.json())
    .then((data)=>{
        promoSkip = data['response'].length + promoSkip
        dispatch({ 
            type: 'promo_data', 
            payload: data['response'], 
            disableButton: data['response'].length <= 15 ? true : data['response'].length == 0 ? true : false
        })
    })
    .catch((er)=>{
        console.log("Error", er)
    })
}

export async function getCuisine(dispatch, getState){
    await fetch(url+'/getCuisine', {
        method: "GET"
    })
    .then((res)=>res.json())
    .then((data)=>{
        let temp = []                 
        for(var i in data['response']){
            temp[i] = {
                "label": data['response'][i]['title'],
                "value": data['response'][i]['title']
            }
        }
        setTimeout(()=>{
            dispatch({ type: 'cuisine_data', payload: temp})
        }, 100)        
    })
    .catch((er)=>{
        console.log("Error", er)
    })
}

export async function getArea(dispatch, getState){
    await fetch(url+'/getArea', {
        method: "GET"
    })
    .then((res)=>res.json())
    .then((data)=>{
        let temp = data['response']    
        dispatch({ type: 'area_data', payload: temp})
    })
    .catch((er)=>{
        console.log("Error", er)
    })
}

export async function getCity(dispatch, getState){
    await fetch(url+"/getTemp?collection=city",{
        method: "GET"            
    })
    .then(res => res.json())
    .then((data)=>{
        if(data['status']=='okay'){            
            let temp = []                 
            for(var i in data['response']){
                temp[i] = {
                    "label": data['response'][i]['ct'],
                    "value": data['response'][i]['ct']
                }
            }
            setTimeout(()=>{
                dispatch({ type: 'cityData', payload: temp})
            }, 100)
        }
    })
    .catch((er)=>{
        console.log("Error", er)
    })
}

export async function getSpecialFoods(dispatch, getState){
    await fetch(url+"/getTemp?collection=specialFoods",{
        method: "GET"            
    })
    .then(res => res.json())
    .then((data)=>{
        if(data['status']=='okay'){
            let temp = []                 
            for(var i in data['response']){
                temp[i] = {
                    "label": data['response'][i]['sf'],
                    "value": data['response'][i]['sf']
                }
            }
            setTimeout(()=>{
                dispatch({ type: 'specialFoodsData', payload: temp})
            }, 100)
        }      
    })
    .catch((er)=>{
        console.log("Error", er)
    })
}

export async function getFeatureAndTags(dispatch, getState){
    await fetch(url+"/getFt",{
        method: "GET"            
    })
    .then(res => res.json())
    .then((data)=>{
        if(data['status']=='okay'){
            let temp = []                 
            for(var i in data['response']){
                temp[i] = {
                    "label": data['response'][i]['ft'],
                    "value": data['response'][i]['ft']
                }
            }
            setTimeout(()=>{
                dispatch({ type: 'feature&tagsData', payload: temp})
            }, 100)
        }
    })
    .catch((er)=>{
        console.log("Error", er)
    })
}



export async function fetchPromoFieldTypes(dispatch, getState){
    await fetch(url+'/getPromoType', {
        method: "GET"
    })
    .then((res)=>res.json())
    .then((data)=>{                                        
        if(data['status']=='okay'){
            const ptTemp = {"All": "all"}
            data['response'].map(item =>{
                delete item["_id"]
                ptTemp[item['fName']] = item['fValue']                                
            })        
            dispatch({ type: 'promo_ft', payload: ptTemp})
        }        
    })
    .catch((er)=>{
        console.log("Error", er)
    })
}

export async function checkUserlogIn(dispatch, getState){    
    firebase.auth().onAuthStateChanged(user =>{
        if(user){
            dispatch({ type: 'user_signed_in', payload: true })            
        }
    })
}

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware))

export default store;