import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-2-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";

import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";


import { PrimaryButton as PrimaryButtonBase } from "ofc/misc/Buttons.js";
import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;


const Rating = tw.span`ml-2 font-bold`;
const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const TestimonialSliderContainer = tw.div`mt-24`;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center lg:mx-4 xl:mx-8 max-w-sm  md:items-stretch md:flex-row md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded`}
  }
`;
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-2xl xl:text-3xl`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-sm`;

const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 opacity-50  top-0 left-0 mb--10 `;
const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500  bottom-0 right-0`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;

const BodyTextContainer = tw.div`md:mx-3 lg:mx-6 md:w-10/12 py-4 flex flex-col justify-between font-medium text-sm`;

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }


`;



const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0">
    <button {...props}>
      <ArrowRightIcon />
    </button>
  </SliderControlButtonContainer>
);
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0">
    <button {...props}>
      <ArrowLeftIcon />
    </button>
  </SliderControlButtonContainer>
);

const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline `}
`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default ({
  testimonials,
  history
}) => {
  /*
   * You can modify the testimonials shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  // const testimonials = [
  //   {
  //     imageSrc:
  //       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
  //     quote:
  //       "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
  //     customerName: "Charlotte Hale",
  //     customerTitle: "CEO, Delos Inc."
  //   },
  //   {
  //     imageSrc:
  //       "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
  //     quote:
  //       "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
  //     customerName: "Adam Cuppy",
  //     customerTitle: "Founder, EventsNYC"
  //   },
  //   {
  //     imageSrc:
  //       "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=512&h=512&q=80",
  //     quote:
  //       "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
  //     customerName: "Steven Marcetti",
  //     customerTitle: "Event Manager, Brite"
  //   }
  // ];

//   addedby: "admin"
// featured_image: "d77d8453b27c4fdf8632fefe5f9f6a3e.jpg"
// gallery_images: (3) ['e3a273e6d05e41458cb8e6f551b815d4.jpg', '610cb0ca56c04f3194553f87c671a1d1.jpg', 'eb88de942e7b404ba12ff365c388db1a.jpg']
// rating: "4"
// res_id: "616591922b24f39bc10fa488"
// res_name: "Ottoman’s Turkish Baklava & Künefe"
// status: true
// summary: "Despite a bleak year, there are a burst of feisty openings, determined to keep staff employed in the hope of a better tomorrow. Now, stay home, stay safe and explore the world\nSleek and elegant, Oyama welcomed its first customers in March. Less than a month later, it is shifting its menu to delivery-only bento boxes. Nevertheless, Andrew Rex is excited about the opportunity to serve Chennai."
// title: "These Chennai restaurants take you to Japan, Italy and Turkey, via their takeaway box"
// video: "https://www.youtube.com/watch?v=YAP6x7Z2614"


  useEffect(()=>{
    console.log("Expert Review", testimonials)
  }
  ,[])


  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Expert Reviews</HeadingTitle>
          <HeadingDescription></HeadingDescription>
        </HeadingInfoContainer>
            <ThreeColumn>
     
            {testimonials.map((testimonial, index) => (

              <Column key={index}>
              <Card>
                <Image imageSrc={"https://bff.bestfoodfinders.com/images/"+testimonial.featured_image} alt={testimonial.addedby} />
                <Details>
                  <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{testimonial.addedby}</div>
                    </Meta>
                    <Meta>
                      <StarIcon />
                      <div>{testimonial.rating}</div>
                    </Meta>
                  </MetaContainer>
                 <Title>{testimonial.title}</Title>
                  <Description>{testimonial.summary}</Description>
                  {/* <Link href={"/expertreview/" + testimonial.title}>Read More</Link> */}
                  <Link  onClick={()=>history.push({pathname: "/expertreview/"+testimonial.title})}>Read More</Link>
                </Details>
              </Card>
            </Column>


            ))}
      
             </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
