const firebaseConfig = {

  //    apiKey: "AIzaSyBoNdyaWvzrMvPQx4wMzHdkCP1sDR4P_q8",
  // authDomain: "bff-website-326612.firebaseapp.com",
  // projectId: "bff-website-326612",
  // storageBucket: "bff-website-326612.appspot.com",
  // messagingSenderId: "426771889823",
  // appId: "1:426771889823:web:cefe95aafb988482f9acaa",
  // measurementId: "G-Y8EHRC8QGY"
  apiKey: "AIzaSyDI8RWs1bySac63grOkwcVa4RRLlg13XTg",
  authDomain: "restaurant-c0a90.firebaseapp.com",
  projectId: "restaurant-c0a90",
  storageBucket: "restaurant-c0a90.appspot.com",
  messagingSenderId: "762110991644",
  appId: "1:762110991644:web:baba56a53b10d1c085eed9",
  measurementId: "G-VF0833PQ7E"

};

export default firebaseConfig;