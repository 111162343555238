import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "ofc/misc/Layouts.js";
import { SectionHeading } from "ofc/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "ofc/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import firebase from 'firebase/app';
import 'firebase/auth';
import { fetchFilterdPromoData } from '../../actions'
import { useDispatch } from 'react-redux'
import { url, promos } from "../../constant";

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-4 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 `;
const CardContainer = tw.div`mt-1 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0  `;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center border-2  border-dashed border-primary-100 `}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-primary-600`;
const CardContentGreen = tw.p`mt-1 text-sm font-medium text-green-700`;
const CardContentTwo = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const TabCardGrid = ({
  heading = "Your promotions",
  tabs = {
    Anytime:'anyTime',
    Breakfast: 'breakFast',
    Lunch: 'lunch',
    Dinner: 'dinner'
  },
  cards,
  history,
  email
}) => {
  const [displayInput, setDisplayInput ] = useState(false)
  const [ key, setKey ] = useState('');
  const [ card, setCards ] = useState([]);
  const [ loading, setLoading ] = useState(true)
  
  useEffect(()=>{
    setTimeout(()=>{
      cards.map((item, index)=>{           
        fetch(url+'/getPromoBasedOnPromoCode',{
          method: "POST",
          body: JSON.stringify({"cde": item.code}) 
        })
        .then(res => res.json())
        .then((data)=>{                  
          if(data['status']=="okay"){
            if(data['response'] != ""){              
              const tempObj = {
                title: data['response']['title'],
                res: data['response']['res_name'],
                promoImage: data['response']['promoImage'],
                status: item.status,
                valid_upto: data['response']['valid_upto'],
                price: data['response']['price'],
                couponType: data['response']['couponType'],
                deleted: data['response']['deleted'],
                receivedOn: item.receivedOn,
                res_id: data['response']['res_id'],
                promoCode: item.code,
                email: email
              }
              cards[index] = tempObj
              setCards(cards)
            } else {              
              const tempObj = {
                deleted: true,
                status: item.status,
                receivedOn: item.receivedOn,
                promoCode: item.code,
                email: email
              }
              cards[index] = tempObj
              setCards(cards)
            }
          }
        })
        .catch((err)=>{
          alert('Something went wrong')
        })
      })  
    }, 300)
  
    setTimeout(()=>{
      setLoading(false)
    }, 1000)
  },[])

  const handleSubmit = (e, code, res_id, receivedOn, type, valid_upto) => {    
    e.preventDefault();
    
    const dateKey = receivedOn.split(' ')
    const date = dateKey[0].split('-')
    const day = date[0]
    const month = date[1]
    const year = date[2]

    let hour = dateKey[1].split(':')[0]

    const minute = dateKey[1].split(':')[1]
    const meridian = dateKey[2]

    if(meridian == 'PM'){
      hour = parseInt(hour) + 12;
    }

    const receiveDate = new Date(year, parseInt(month) - 1, day, hour, minute)

    let sec = Math.floor((new Date() - receiveDate)/1000);
    let minutes = Math.floor(sec / 60);    
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);

    hours = hours-(days*24)    
    if(type == "Sub coupon"){  
      if(hours > 24) {
        const obj  = { email: email, code: code, key: key, uid: firebase.auth().currentUser.uid, resId: res_id }
        fetch(url+'/consumePromo',{
          method: "POST",
          body: JSON.stringify(obj) 
        })
        .then(res => res.json())
        .then((data)=>{          
          if(data['status']=="okay"){
            fetch(url+'/getNumberMainPromo', {
              method: "POST",
              body: JSON.stringify({"id": res_id}) 
            })
            .then(res => res.json())
            .then((data)=>{          
              if(data['status']=="okay"){

                if(data['response']['length'] > 0){              
                  history.push({pathname: '/otherPromotions', state: { data: res_id } })
                  window.location.reload()
                }
                else if(data['response']['length'] < 0){
                  window.location.reload()
                }
              }          
            })
            .catch((err)=>{
              alert('Something went wrong')
            })
          }
          if(data['status']=="invalidKey"){
            alert("Invalid key")
            // window.location.reload()
          }
        })
      } else {
        alert("Consume this after 24 hours from the receiving time")
        window.location.reload()
      }
    }

    if(type == "Main coupon") {
      const obj  = { email: email, code: code, key: key, uid: firebase.auth().currentUser.uid, resId: res_id  }
      fetch(url+'/consumePromo',{
        method: "POST",
        body: JSON.stringify(obj) 
      })
      .then(res => res.json())
      .then((data)=>{        
        if(data['status']=="okay"){
          fetch(url+'/getNumberMainPromo',{
            method: "POST",
            body: JSON.stringify({"id": res_id}) 
          })
          .then(res => res.json())
          .then((data)=>{          
            if(data['status']=="okay"){
              if(data['response']['length'] > 0){              
                history.push({pathname: '/otherPromotions', state: { data: res_id } })
                window.location.reload()
              }
              else if(data['response']['length'] <= 0){
                window.location.reload()
              }                          
            }
            else if(data['status']=='err'){
              alert('Something went wrong')
            }          
          })
        }
        if(data['status']=="invalidKey"){
          window.location.reload()
          alert("Invalid key")
        }
      })
    }    
  }

  const handleChange = (e) =>{
    setKey(e.target.value)
  }

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          {loading && <Header>Loading...</Header>}
          {!loading && card.length > 0 && <Header>{heading}</Header>}
        </HeaderRow>

          <TabContent
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
          >
            {card.map((card, index) => (
              card.deleted ? '' : <CardContainer key={index}>
                <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={promos+card.promoImage }>                    
    {/*                      {card.status == 'cnd' ? '' : <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                 
                      { displayInput ?
                        <Form onSubmit={(e)=>handleSubmit(e, card.promoCode, card.res_id, card.receivedOn, card.couponType, card.valid_upto)}>
                          <Input type="password" placeholder="Key" name='key' onChange={handleChange} />                          
                          <SubmitButton type="submit">                          
                            <span className="text">Validate</span>
                          </SubmitButton>
                        </Form> :
                        <CardButton onClick={()=> setDisplayInput(true)}>Use now</CardButton>
                      } 
                    </CardHoverOverlay>}*/}
                  </CardImageContainer>
                  {card.status == 'cnd' ? <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardTitle>{card.res}</CardTitle>
                    <CardContentGreen>Consumed</CardContentGreen>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                  :<CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardTitle>{card.res}</CardTitle>
                    <CardContentTwo>Valid upto {" "}{card.valid_upto}</CardContentTwo>
                    <CardContent>Received</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                  }
                </Card>
              </CardContainer>
            ))}
          </TabContent>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

export default withRouter(TabCardGrid);