
import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "ofc/hero/RestaurantHero.js";
import TabGrid from "ofc/cards/TabGridForRes";
import TabGridDelivery from "ofc/cards/TabGridForDelivery";
import Testimonial from "ofc/testimonials/SimplePrimaryBackground.js"; 
import ExpertReviewSlider from "ofc/testimonials/ExpertReviewSlider.js"; 

import ExpertReviewSlider1 from "ofc/testimonials/ThreeColumnWithProfileImage.js"; 

import GridForExpertReviews from "ofc/cards/GridForExpertReviews.js"; 
//import ExpertReviews from "ofc/cards/ExpertReviewSlider.js"; 

import DownloadApp from "ofc/cta/DownloadApp.js";
import Footer from "ofc/footers/FiveColumnWithInputForm.js";

import styled from "styled-components";

import Header from "ofc/headers/light.js";

import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";

import { ReactComponent as WebIcon } from "feather-icons/dist/icons/mouse-pointer.svg";


import { ReactComponent as PhoneIcon } from "feather-icons/dist/icons/phone-call.svg";

import { ReactComponent as ClockIcon } from "feather-icons/dist/icons/clock.svg";

import { ReactComponent as FacebookIcon } from "feather-icons/dist/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "feather-icons/dist/icons/instagram.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"

import { ReactComponent as DropDown } from "images/chevron-down.svg"
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";

import { RestaurantHeading } from "ofc/misc/Headings";

import chefIconImageSrc from "images/chef-icon.svg";


import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import { useParams, withRouter } from "react-router-dom";
import { url, prefixImageUrl } from "../constant";

import './timingsPopUp.css'


import {InlineShareButtons} from 'sharethis-reactjs';

import DocumentMeta from 'react-document-meta';


const RestaurantDisplay = ({location, history}) => {

  const [card, setCard ] = useState({})
  const [cuisine, setCuisine ] = useState([])
  const [images, setImages ] = useState([])
  const [promoCard, setPromoCard] =useState([])
  const [reviews, setReviews] =useState([])
  const [expertReviews, setExpertReviews] =useState([])

  const { id } = useParams();

  useEffect(()=>{
    window.scrollTo(0, 0)    
    if(location.state == undefined) {
      fetch(url+'/getSpecificRes',{
        method: "POST",
        body: JSON.stringify({"routeField": id})
      })
      .then(res => res.json())
      .then((data)=>{
        if(data["status"]=="okay"){
          if(String(data['response']['featured_image']).length === 32) {                    
            data['response']['featured_image'] = prefixImageUrl+data['response']['featured_image']+'.jpg';
          } else if (String(data['response']['featured_image']).length === 36) {
            data['response']['featured_image'] =  prefixImageUrl+data['response']['featured_image']
          }

          setCard(data['response']);
          setCuisine(data['response']['cuisines'])
          setImages(data['response']['gallery_images'])
          
          fetch(url+'/getSpecificRestaurantPromo', {
            method: 'POST',
            body: JSON.stringify({'res_id': data['response']["_id"]["$oid"], 'couponType': 'Main coupon', "deleted": false } )
          })
          .then(res => res.json())
          .then((data)=> {
            setPromoCard(data['response'])
          }) 
          .catch(err=>{
            alert("Something went wrong")
          })

          let headers = new Headers();
          
          headers.append('Content-Type', 'application/json');
          headers.append('Accept', 'application/json');
          headers.append('Access-Control-Allow-Origin', 'http://bff.bestfoodfinders.com/');
          headers.append('Access-Control-Allow-Credentials', 'true');
          headers.append('GET', 'POST', 'OPTIONS');

          fetch(url+'/getReviews',{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({'id': data['response']["_id"]["$oid"] })
          })
          .then(res => res.json())
          .then((data)=>{
            setReviews(data['response'])         
          }) 
          .catch(err=>{
            alert("Something went wrong")
          })


          fetch(url+'/getExpertReviews',{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({'id': data['response']["_id"]["$oid"] })
          })
          .then(res => res.json())
          .then((data)=>{
            setExpertReviews(data['response'])
            console.log(data['response'],"EXP Rev")         
          }) 
          .catch(err=>{
            alert("Something went wrong")
          })





        }
      })
    }    
      

    if(location.state != undefined){
      console.log(location.state.data)
      setCard(location.state.data)
      setCuisine(location.state.data.cuisines)
      setImages(location.state.data.gallery_images)
     
      fetch(url+'/getSpecificRestaurantPromo',{
        method: 'POST',
        body: JSON.stringify({'res_id': location.state.data['_id']['$oid'], 'couponType': 'Main coupon', "deleted": false })
      })
      .then(res => res.json())
      .then((data)=>{
          setPromoCard(data['response'])        
          console.log(data)  
      }) 
      .catch(err=>{
        console.log(err)
      })

      fetch(url+'/getReviews',{
        method: 'POST',
        body: JSON.stringify({'id': location.state.data["_id"]["$oid"] })
      })
      .then(res => res.json())
      .then((data)=>{
        setReviews(data['response'])
      }) 
      .catch(err=>{
        alert("Something went wrong")
      })


         fetch(url+'/getExpertReviews',{
            method: 'POST',
            body: JSON.stringify({'id': location.state.data["_id"]["$oid"] })
          })
          .then(res => res.json())
          .then((data)=>{
            setExpertReviews(data['response'])
            console.log(data['response'],"EXP Rev")         
          }) 
          .catch(err=>{
            alert("Something went wrong exp. review")
          })



    }
  }, [])

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto`;

const VidContainer= tw.div`max-w-screen-md mx-auto w-full`;

const Subheading = tw.span`tracking-wider text-sm font-medium`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
const Description = tw.span`inline-block mt-8`;
const imageCss = tw`rounded-4xl`;

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6 `;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-stretch `;
const Title = tw.h3`text-3xl font-bold text-left `;


const Opentime = tw(motion.a)`ml-2 text-sm font-semibold  text-primary-500`;
const OpentimeGreen = tw(motion.a)`ml-2 text-sm font-semibold  text-green-700`;
const Closetimered = tw(motion.a)`ml-2 text-sm font-semibold  text-red-700`;
const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;

const Cusines= tw.span`mx-2 font-semibold bg-gray-100 rounded-l px-3 text-sm   justify-center`;

const Rating = tw.span`ml-2 font-bold`;
{/*   padding-bottom: 56.25% !important;*/}
const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`

  padding-bottom: 56.25% !important; 

  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl `}
  }
`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4 justify-center`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-4`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const IconContainerNoBr = styled.div`
  ${tw`inline-block`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const TextWithMargin = tw.div`m-2 text-sm font-semibold text-gray-800`;
const RestaurantHeading = tw.h4`text-2xl sm:text-2xl font-black tracking-wide text-primary-200`
const Heading = tw(RestaurantHeading)``;

const TextHint = tw.div`ml-2 text-sm font-semibold text-red-800`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const CardImage = styled.img(props => [
  `src: url("${props.imageSrc}");`,
  tw`w-24 h-24  bg-cover bg-center rounded sm:rounded-none sm:rounded-l inline-block`
]);

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const ColumnTL = tw.div`w-10/12  flex flex-col px-2`;
const ColumnTR = tw.div`w-2/12 flex flex-col px-2`;
const days =  [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ]

const get24HrFormat = (key) =>{
  if(key.split(" ")[1] == 'PM'){
    if(parseInt(key.split(":")[0]) == 12){
      return parseInt(key.split(":")[0]);      
    }
    return parseInt(key.split(":")[0]) + 12;    
  } else {
    return parseInt(key.split(":")[0]);
  }
}

const getMinutes = (key) =>{
  return parseInt(key.split(":")[1]);
}

const getCurrentStatus = (key) =>{
  const day = days[new Date().getDay()];
  const currentHr = new Date().getHours();
  const currentMins = new Date().getMinutes();  
  let fromTime;
  let toTime;
  let fromTime1;
  let toTime1;

  key.map((item)=>{
    Object.keys(item).map((key)=>{
      if(key == day){
        if(item[key]['fT']!='No'){
          fromTime = item[key]['fT'];
          toTime = item[key]['tT'];
        } 
        if(item[key]['fT1']!='No'){
          fromTime1 = item[key]['fT1'];
          toTime1 = item[key]['tT1'];
        }
      }
    })
  })
  
  if(fromTime){
    let fromTimeHour = get24HrFormat(fromTime);
    let toTimeHour  = get24HrFormat(toTime);

    let fromTimeMin = getMinutes(fromTime)
    let toTimeMin = getMinutes(toTime)

    let fromTimeHour1;
    let toTimeHour1;

    let fromTimeMin1;
    let toTimeMin1;

    if(fromTime1){    
      fromTimeHour1  = get24HrFormat(fromTime1);
      toTimeHour1  = get24HrFormat(toTime1);

      fromTimeMin1 = getMinutes(fromTime1)
      toTimeMin1 = getMinutes(toTime1)
    }  

    let fromHrDiff = fromTimeHour - currentHr;
    let toHrDiff =  toTimeHour - currentHr;

    let fromMinDiff = fromTimeMin - currentMins;
    let toMinDiff = toTimeMin - currentMins;

    let fromHrDiff1;
    let toHrDiff1;

    let fromMinDiff1;
    let toMinDiff1;

    if(fromTime1){
      fromHrDiff1 = fromTimeHour1 - currentHr;
      toHrDiff1 =  toTimeHour1 - currentHr;

      fromMinDiff1 = fromTimeMin1 - currentMins;
      toMinDiff1 = toTimeMin1 - currentMins;      
    }

    if(fromHrDiff <= 1){
      if(fromTime1){
        if(toHrDiff1 <= 1){
          if(toHrDiff1 == 1){
            return "Closing Soon"
          } else if(toHrDiff1 == 0){
            if(toMinDiff1 > 0){
              return "Closing Soon"
            }
            return "Closed Now"
          } else if(toHrDiff1 < 0){
            if(toMinDiff1 > 0){
              return "Closing Soon"
            }
            return "Closed Now"
          }
        }     
    
        if(fromHrDiff1 <= 1){
          if(fromHrDiff1 == 1){
            return "Opening Soon"
          } else if(fromHrDiff1 == 0){
            if(fromMinDiff1 > 0){
              return "Opening Soon"              
            }
            return "Open Now"
          } else if(fromHrDiff1 < 0){
            if(fromMinDiff1 > 0){
              return "Opening Soon"              
            }
            return "Open Now"
          }          
        }
      }

      if(toHrDiff == 1){
        return "Closing Soon"
      } else if(toHrDiff == 0){      
        if(toMinDiff > 0){
          return "Closing Soon"
        }
        return "Closed Now"
      } else if(toHrDiff < 0){      
        if(toMinDiff1 > 0){
          return "Closing Soon"
        }
        return "Closed Now"
      }

      if(fromHrDiff == 1){
        if(fromMinDiff > 0){
          return "Opening Soon"              
        }
        return "Open Now"
      } else if(fromHrDiff <= 0) {
        if(fromMinDiff > 0){
          return "Opening Soon"              
        }
        return "Open Now"
      }
    } else {
      return "Closed Now"
    }
  } else {
    return "Closed Now"
  }
}

    const meta = {
      title: 'Restaurants Near Me – Find Best Restaurants in India | Best Food Finders',
      description: 'Discover the best restaurants near you at Best Food Finders. Choose from over 1000 different restaurants to enjoy a delicious meal at.',
      canonical: 'http://bff.bestfoodfinders.com/restaurants',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'Best Restaurants Near Me.'
        }
      }
    };

  return (    
  
  <AnimationRevealPage>
      <DocumentMeta {...meta} />
   <Header />
      {images && <Hero images={images} altKey={card.name+" "+card.shortDesceription} /> }
         <Container>
      <Content>     
      <TextContent>     
                <TitleReviewContainer>
                <img src={card.featured_image} alt={card.name+" "+card.shortDesceription} style={{height: 150, width: 150}} />
                  <Title> {card.name}  
                  {cuisine.map((item) =>
                    <Cusines>{item}</Cusines>
                  )}    
                  <br/>
                  {card.address ? <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.address}</Text>
                    </IconWithText> : '' 
                  }
                  {card.phone ? <IconWithText>
                    <IconContainer>
                      <PhoneIcon />
                    </IconContainer>
                  <Text>{card.phone}</Text>
                  </IconWithText> : '' }

                {card['operatingTime'] ? 
                  <div class="dropdown">           
                    <IconWithText>
                      <IconContainer>
                        <ClockIcon />
                      </IconContainer>       
                        {getCurrentStatus(card['operatingTime']).split(" ")[0].split()[0] == "O" ? <OpentimeGreen class="dropbtn">
                        {getCurrentStatus(card['operatingTime'])}
                      </OpentimeGreen> :
                      <Closetimered>
                        {getCurrentStatus(card['operatingTime'])}
                      </Closetimered>}
                      <IconContainerNoBr>
                        <DropDown />
                      </IconContainerNoBr>
                    </IconWithText>
                    <div class="dropdown-content">                    
                      {card.operatingTime.map(item =>
                        Object.keys(item).map((key, index)=>
                          item[key]['fT1'] ? 
                            <TextWithMargin>
                              <div style={{display: 'flex'}}>
                                <div style={{ width: 70}} >{key}</div>                               
                                <div style={{display: 'flex', flex: 1, justifyContent: 'center'}} >{item[key]['fT']=='No' ? '--:--' : item[key]['fT']}{" "} to {" "} {item[key]['tT']=='No' ? '--:--' : item[key]['tT']} &nbsp;</div>
                                <div style={{display: 'flex', flex: 1, justifyContent: 'center'}} >& &nbsp; {item[key]['fT1']=='No' ? '--:--' : item[key]['fT1']}{" "} to {" "} {item[key]['tT1']=='No' ? '--:--' : item[key]['tT1']}</div>
                              </div>
                            </TextWithMargin> : 
                            <TextWithMargin>
                              <div style={{display: 'flex'}}>
                                <div style={{ width: 70}} >{key}</div>                               
                                <div style={{display: 'flex', flex: 1, justifyContent: 'center'}} >{item[key]['fT']+" to "+item[key]['tT']} &nbsp;</div> 
                              </div>
                            </TextWithMargin>
                        )  
                      )}
                    </div>
                  </div> : ''
                }
                </Title>

           
                {card.rating ? 
                  <RatingsInfo>
                    <StarIcon />                    
                      <Rating>{parseFloat(card.rating).toFixed(1)}</Rating>
                      <Rating>Editor's Rating</Rating>
                  </RatingsInfo>: 
                  <RatingsInfo>                  
                    <Rating>No reviews yet</Rating>
                  </RatingsInfo>
                }


                </TitleReviewContainer>  


              </TextContent>

       <SecondaryInfoContainer>
 {card.web_url ? <IconWithText>
                    <IconContainer>
                      <WebIcon />
                    </IconContainer>
                    <Opentime href={card.web_url} target="_blank">Website</Opentime>
                  </IconWithText> : '' }
                      {card.fb_url ? <IconWithText>
                    <IconContainer>
                      <FacebookIcon />
                    </IconContainer>
                  <Opentime  href={card.fb_url} target="_blank">Facebook</Opentime>
                  </IconWithText> : '' }
                  
                  {card.insta_id ? <IconWithText>
                    <IconContainer>
                      <InstagramIcon />
                    </IconContainer>
                    <Opentime href={card.insta_id} target="_blank">Instagram</Opentime>
                  </IconWithText> : '' }
                  
                  <br/>
                </SecondaryInfoContainer>

          { card.description ? <TextContent>
           { card.shortDesceription ? <center><Heading>{card.shortDesceription}</Heading></center> :''}
<br/>
              <center>
<VidContainer>
          { card.youtube_url ? <StyledResponsiveVideoEmbed          
            url={"https://www.youtube.com/embed/"+card.youtube_url}
            background="transparent"
          /> : '' }

          </VidContainer> </center>

            <Description>
            <div dangerouslySetInnerHTML={{ __html: card.description }}></div>
   
            <br /></Description>          
          </TextContent> : '' }
 </Content>
 <br/><br/>

 <InlineShareButtons
          config={{
            alignment: 'center',  // alignment of buttons (left, center, right)
            color: 'social',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 16,        // font size for the buttons
            labels: 'cta',        // button labels (cta, counts, null)
            language: 'en',       // which language to use (see LANGUAGES)
            networks: [           // which networks to include (see SHARING NETWORKS)
              'whatsapp',
              'facebook',
              'instagram',
              'twitter',
              'pinterest'
            ],
            padding: 12,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: true,
            size: 40,             // the size of each button (INTEGER)

            // OPTIONAL PARAMETERS
          url: 'http://bff.bestfoodfinders.com/', // (defaults to current url)
            image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
            description: 'Check this restaurant in BestFoodFinders.com',       // (defaults to og:description or twitter:description)
            title: 'BestFoodFinders',            // (defaults to og:title or twitter:title)
            message: 'BestFoodFinders',     // (only for email sharing)
            subject: 'BestFoodFinders',  // (only for email sharing)
            username: 'BestFoodFinders' // (only for twitter sharing)
          }}
        />        
      </Container>
      {promoCard.length > 0 ? <TabGrid promoCard={promoCard} /> : <div style={{margin : 20}} ></div>}    
      {card['delivery'] && card['delivery'].length > 0 &&  <TabGridDelivery promoCard={card['delivery']} />}
  
      {/* <ReviewForm resId={card['_id']} resName={card.name} /> */}

            
            {expertReviews.length > 0 && <ExpertReviewSlider  history= {history} testimonials={expertReviews}/>}   

          
      {reviews.length > 0 && <Testimonial testimonials={reviews}  />}     
      <Footer />
    </AnimationRevealPage>
  );
}

export default withRouter(RestaurantDisplay);