
import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "ofc/hero/RestaurantHero.js";
import TabGrid from "ofc/cards/TabGridForRes";
import TabGridDelivery from "ofc/cards/TabGridForDelivery";
import Testimonial from "ofc/testimonials/SimplePrimaryBackground.js"; 
import ExpertReviewSlider from "ofc/testimonials/ExpertReviewSlider.js"; 
import { SectionHeading as HeadingTitle, Subheading } from "ofc/misc/Headings.js";
import ExpertReviewSlider1 from "ofc/testimonials/ThreeColumnWithProfileImage.js"; 
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";

import GridForExpertReviews from "ofc/cards/GridForExpertReviews.js"; 
//import ExpertReviews from "ofc/cards/ExpertReviewSlider.js"; 

import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";


import DownloadApp from "ofc/cta/DownloadApp.js";
import Footer from "ofc/footers/FiveColumnWithInputForm.js";

import styled from "styled-components";

import Header from "ofc/headers/light.js";

import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";

import { ReactComponent as WebIcon } from "feather-icons/dist/icons/mouse-pointer.svg";


import { ReactComponent as PhoneIcon } from "feather-icons/dist/icons/phone-call.svg";

import { ReactComponent as ClockIcon } from "feather-icons/dist/icons/clock.svg";

import { ReactComponent as FacebookIcon } from "feather-icons/dist/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "feather-icons/dist/icons/instagram.svg";
import {ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"

import {ReactComponent as DropDown } from "images/chevron-down.svg"
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";

import { RestaurantHeading } from "ofc/misc/Headings";

import chefIconImageSrc from "images/chef-icon.svg";


import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import { useParams, withRouter } from "react-router-dom";
import { url, prefixImageUrl } from "../constant";

import './timingsPopUp.css'
import { PrimaryButton as PrimaryButtonBase } from "ofc/misc/Buttons.js";

import {InlineShareButtons} from 'sharethis-reactjs';

import DocumentMeta from 'react-document-meta';

const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const ExpertPage = ({location}) => {

  const [card, setCard ] = useState({})
  const [cuisine, setCuisine ] = useState([])
  const [images, setImages ] = useState([])
  const [promoCard, setPromoCard] =useState([])
  const [reviews, setReviews] =useState([])
  const [expertReviews, setExpertReviews] =useState([])

    const [expertProfile, setexpertProfile ] = useState({})


  const { id } = useParams();

  useEffect(()=>{
    window.scrollTo(0, 0)    
    if(location.state == undefined) {
      fetch(url+'/getExpertProfile',{
        method: "POST",
        body: JSON.stringify({"expertUserID": id})
      })
      .then(res => res.json())
      .then((data)=>{
        if(data["status"]=="okay"){
          if(String(data['response']['featured_image']).length === 32) {                    
            data['response']['profile_image'] = prefixImageUrl+"static/media/icon.f2bb8b21.svg";
          } else if (String(data['response']['featured_image']).length === 36) {
            data['response']['profile_image'] =  prefixImageUrl+"static/media/icon.f2bb8b21.svg";
          }

          setexpertProfile(data['response']);
       
         let headers = new Headers();
          
          headers.append('Content-Type', 'application/json');
          headers.append('Accept', 'application/json');
          headers.append('Access-Control-Allow-Origin', 'http://bestfoodfinders.com');
          headers.append('Access-Control-Allow-Credentials', 'true');
          headers.append('GET', 'POST', 'OPTIONS');
         


          fetch(url+'/getExpertReviewsbyreviewer',{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({'addedby': id })
          })
          .then(res => res.json())
          .then((data)=>{
            setExpertReviews(data['response'])
            console.log(data['response'],"EXP Rev")         
          }) 
          .catch(err=>{
            alert("Something went wrong")
          })





        }
      })
    }    
      

    if(location.state != undefined){
      // console.log(location.state.data)
      // setCard(location.state.data)
      // setCuisine(location.state.data.cuisines)
      // setImages(location.state.data.gallery_images)
     

  
      //    fetch(url+'/getExpertReviews',{
      //       method: 'POST',
      //       body: JSON.stringify({'id': location.state.data["_id"]["$oid"] })
      //     })
      //     .then(res => res.json())
      //     .then((data)=>{
      //       setExpertReviews(data['response'])
      //       console.log(data['response'],"EXP Rev")         
      //     }) 
      //     .catch(err=>{
      //       alert("Something went wrong exp. review")
      //     })



    }
  }, [])

const Container = tw.div`relative `;
const Content = tw.div`max-w-screen-xl mx-auto`;

const VidContainer= tw.div`max-w-screen-md mx-auto w-full`;

const Subheading = tw.span`tracking-wider text-sm font-medium`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
const Description = tw.span`inline-block mt-8`;
const imageCss = tw`rounded-4xl`;

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6 `;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-stretch `;
const Title = tw.h3`text-3xl font-bold text-left `;

const HeadingInfoContainer = tw.div`flex flex-col items-center sm:w-full sm:mx-0 px-4 lg:px-20 py-10 lg:py-12 bg-primary-500 rounded-lg relative text-white `;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;


const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-700 opacity-50`
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-700 opacity-50`

const DecoratorBlob3 = tw(SvgDotPatternIcon)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`
const DecoratorBlob4 = tw(SvgDotPatternIcon)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`


const Opentime = tw(motion.a)`ml-2 text-sm font-semibold  text-primary-500`;
const OpentimeGreen = tw(motion.a)`ml-2 text-sm font-semibold  text-green-700`;
const Closetimered = tw(motion.a)`ml-2 text-sm font-semibold  text-red-700`;
const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;

const Cusines= tw.span`mx-2 font-semibold bg-gray-100 rounded-l px-3 text-sm   justify-center`;

const Rating = tw.span`ml-2 font-bold`;
{/*   padding-bottom: 56.25% !important;*/}
const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`

  padding-bottom: 56.25% !important; 

  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl `}
  }
`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4 justify-center`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-4`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const IconContainerNoBr = styled.div`
  ${tw`inline-block`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const TextWithMargin = tw.div`m-2 text-sm font-semibold text-gray-800`;
const RestaurantHeading = tw.h4`text-2xl sm:text-2xl font-black tracking-wide text-primary-200`
const Heading = tw(RestaurantHeading)``;

const TextHint = tw.div`ml-2 text-sm font-semibold text-red-800`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const CardImage = styled.img(props => [
  `src: url("${props.imageSrc}");`,
  tw`w-24 h-24  bg-cover bg-center rounded sm:rounded-none sm:rounded-l inline-block`
]);

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const ColumnTL = tw.div`w-10/12  flex flex-col px-2`;
const ColumnTR = tw.div`w-2/12 flex flex-col px-2`;
const days =  [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ]


const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const get24HrFormat = (key) =>{
  if(key.split(" ")[1] == 'PM'){
    if(parseInt(key.split(":")[0]) == 12){
      return parseInt(key.split(":")[0]);      
    }
    return parseInt(key.split(":")[0]) + 12;    
  } else {
    return parseInt(key.split(":")[0]);
  }
}

const getMinutes = (key) =>{
  return parseInt(key.split(":")[1]);
}

const getCurrentStatus = (key) =>{
  const day = days[new Date().getDay()];
  const currentHr = new Date().getHours();
  const currentMins = new Date().getMinutes();  
  let fromTime;
  let toTime;
  let fromTime1;
  let toTime1;

  key.map((item)=>{
    Object.keys(item).map((key)=>{
      if(key == day){
        if(item[key]['fT']!='No'){
          fromTime = item[key]['fT'];
          toTime = item[key]['tT'];
        } 
        if(item[key]['fT1']!='No'){
          fromTime1 = item[key]['fT1'];
          toTime1 = item[key]['tT1'];
        }
      }
    })
  })
  
  if(fromTime){
    let fromTimeHour = get24HrFormat(fromTime);
    let toTimeHour  = get24HrFormat(toTime);

    let fromTimeMin = getMinutes(fromTime)
    let toTimeMin = getMinutes(toTime)

    let fromTimeHour1;
    let toTimeHour1;

    let fromTimeMin1;
    let toTimeMin1;

    if(fromTime1){    
      fromTimeHour1  = get24HrFormat(fromTime1);
      toTimeHour1  = get24HrFormat(toTime1);

      fromTimeMin1 = getMinutes(fromTime1)
      toTimeMin1 = getMinutes(toTime1)
    }  

    let fromHrDiff = fromTimeHour - currentHr;
    let toHrDiff =  toTimeHour - currentHr;

    let fromMinDiff = fromTimeMin - currentMins;
    let toMinDiff = toTimeMin - currentMins;

    let fromHrDiff1;
    let toHrDiff1;

    let fromMinDiff1;
    let toMinDiff1;

    if(fromTime1){
      fromHrDiff1 = fromTimeHour1 - currentHr;
      toHrDiff1 =  toTimeHour1 - currentHr;

      fromMinDiff1 = fromTimeMin1 - currentMins;
      toMinDiff1 = toTimeMin1 - currentMins;      
    }

    if(fromHrDiff <= 1){
      if(fromTime1){
        if(toHrDiff1 <= 1){
          if(toHrDiff1 == 1){
            return "Closing Soon"
          } else if(toHrDiff1 == 0){
            if(toMinDiff1 > 0){
              return "Closing Soon"
            }
            return "Closed Now"
          } else if(toHrDiff1 < 0){
            if(toMinDiff1 > 0){
              return "Closing Soon"
            }
            return "Closed Now"
          }
        }     
    
        if(fromHrDiff1 <= 1){
          if(fromHrDiff1 == 1){
            return "Opening Soon"
          } else if(fromHrDiff1 == 0){
            if(fromMinDiff1 > 0){
              return "Opening Soon"              
            }
            return "Open Now"
          } else if(fromHrDiff1 < 0){
            if(fromMinDiff1 > 0){
              return "Opening Soon"              
            }
            return "Open Now"
          }          
        }
      }

      if(toHrDiff == 1){
        return "Closing Soon"
      } else if(toHrDiff == 0){      
        if(toMinDiff > 0){
          return "Closing Soon"
        }
        return "Closed Now"
      } else if(toHrDiff < 0){      
        if(toMinDiff1 > 0){
          return "Closing Soon"
        }
        return "Closed Now"
      }

      if(fromHrDiff == 1){
        if(fromMinDiff > 0){
          return "Opening Soon"              
        }
        return "Open Now"
      } else if(fromHrDiff <= 0) {
        if(fromMinDiff > 0){
          return "Opening Soon"              
        }
        return "Open Now"
      }
    } else {
      return "Closed Now"
    }
  } else {
    return "Closed Now"
  }
}

    const meta = {
      title: 'Restaurants Near Me – Find Best Restaurants in India | Best Food Finders',
      description: 'Discover the best restaurants near you at Best Food Finders. Choose from over 1000 different restaurants to enjoy a delicious meal at.',
      canonical: 'http://bff.bestfoodfinders.com/restaurants',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'Best Restaurants Near Me.'
        }
      }
    };

  return (    
  
  <AnimationRevealPage>
      <DocumentMeta {...meta} />
   <Header />
    <HeadingInfoContainer>
        {<Subheading>@{expertProfile.expertUserID}</Subheading>}
          <HeadingTitle>{expertProfile.expertName}</HeadingTitle>
           {<Subheading>Expert Reviewer</Subheading>}
          <HeadingDescription>My Bio</HeadingDescription>
            <DecoratorBlobContainer>
          <DecoratorBlob1/>
          <DecoratorBlob2/>
        </DecoratorBlobContainer>

        </HeadingInfoContainer>


         <Container>
      <Content>     
   
              <center>
<VidContainer>
          { card.youtube_url ? <StyledResponsiveVideoEmbed          
            url={"https://www.youtube.com/embed/"+card.youtube_url}
            background="transparent"
          /> : '' }

          </VidContainer> </center>


 <br/><br/>

 
      <HeadingTitle>My Reviews</HeadingTitle>

<ThreeColumn>
     
            {expertReviews.map((testimonial, index) => (

              <Column key={index}>
              <Card>
                <Image imageSrc={"https://bff.bestfoodfinders.com/images/"+testimonial.featured_image} alt={testimonial.addedby} />
                <Details>
                  <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{testimonial.addedby}</div>
                    </Meta>
                    <Meta>
                      <StarIcon />
                      <div>{testimonial.rating}</div>
                    </Meta>
                  </MetaContainer>
                 <Title>{testimonial.title}</Title>
                  <Description>{testimonial.summary}</Description>
                  <Link href={"/expertreview/" + testimonial.title}>Read More</Link>
                </Details>
              </Card>
            </Column>
            ))}
               <DecoratorBlob3 />
          <DecoratorBlob4 />
      
             </ThreeColumn>
 </Content>
        </Container>   
  
      <Footer />
    </AnimationRevealPage>
  );
}

export default withRouter(ExpertPage);