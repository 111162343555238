import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "ofc/headers/light.js";
import Footer from "ofc/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "ofc/features/TwoColWithButton.js";
// import MainFeature2 from "ofc/features/TwoColSingleFeatureWithStats.js";
import Features from "ofc/features/ThreeColSimple.js";
import { SectionHeading } from "ofc/misc/Headings.js";
import Features2 from "ofc/features/DashedBorderSixFeatures.js"; 
// import Features from "ofc/features/ThreeColWithSideImage.js";
import TeamCardGrid from "ofc/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

import DocumentMeta from 'react-document-meta';

const Subheading = tw.span`uppercase tracking-wider text-sm`;


  const meta = {
    title: 'About Us - Best Food Finders',
    description: 'Best Food Finders is a specially curated online experience designed to help find the best of home kitchens & restaurants around you. ',
    canonical: 'http://bff.bestfoodfinders.com/aboutus',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'best food finders, food guide india, restaurants,near me'
        }
    }};

export default () => {
  return (
 
    <AnimationRevealPage>
            <DocumentMeta {...meta} />
      <Header />

       <Features
        subheading={<Subheading>About BFF</Subheading>}
        heading="We got you covered!"
        description=""
        cards={[
        ]}
        linkText=""
      />
     <center> <p>Want to cover the best of the culinary world?<br/>
Best of restaurants, best of home kitchens, best of home bakers, best of online and the best of offline?<br/>
Wondering about ordering good food to jack up your mood, after a tiresome day?<br/>
<b>Well, we got you covered!</b>
</p></center>

    <Features2
        subheading={<Subheading>About BFF</Subheading>}
        heading="Look no more!"
        description=""
       cards={[
          {
            imageSrc: CustomerLoveIconImage,
            title: "We serve to your pleasure!",
            description: "Best Food Finders serve to your pleasure. Working with the intention of exploring the best food and the flavour, the place has to offer, we are dedicated completely to minimize your work and maximize your experience. "
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Satisfy your hunger!",
            description: "We travel, taste and search for the places that would satisfy your hunger pangs so that you could enjoy the foods from your favourite restaurants."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Search food destinations!",
            description: "You can search classified categories and the destinations from which you would like to order and we will do the rest by bringing to your search tab the best about the restaurant and everything related to food."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Smile with a happy tummy!",
            description: "Not only we explore in and around India but Internationally so that people all over could taste the food which would make them smile with a happy tummy."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "The chef in you!",
            description: "In addition to constantly updating the best restaurants, we are also going to bring you some time-tested dishes with recipes, so that the chef in you could bud with the help of our recipes."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Cherish the experience!",
            description: "Food always brings people closer and makes us happier. We are on a lifetime journey to unite people with food and also cherish the experience that comes when you taste such good food."
          }]}
        linkText=""
      />
 <SectionHeading>Food to unite people!<br/>
Food to unite states!<br/>
Food to unite your world!
</SectionHeading>
<br/>    <center>
So buckle and click the <a href="/restaurants">"Explore Restaurants"</a> page to start your journey of Food which will be the best of journeys you will ever go on!
</center><br/><br/><br/><br/>

    
    
      <Footer />
    </AnimationRevealPage>
  );
};
