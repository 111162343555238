

// export const url = "https://api.bestfoodfinders.com";
//export const prefixImageUrl = "https://bestfoodfinders.com/images/";

//export const prefixImageUrl = "https://bestfoodfinders.com/images/";


// export const prefixImageUrl = "https://bestfoodfinders.com/images/";

// export const url = "https://api2022.bestfoodfinders.com/"

//bff server
export const prefixImageUrl = "https://bestfoodfinders.com/images/";
export const url = "http://191.101.229.230:8000";
export const promos = "https://bestfoodfinders.com/promos/"

//local server

// export const prefixImageUrl = "http://localhost:3001/";
// export const url = "http://127.0.0.1:5000/";


export const cuisine = [
{
"label":"Chinese",
"value": "Chinese"
},
{   
"label": "Malay/Indonesian",
"value": "Malay/Indonesian"        
},
{
"label":"Indian",
 "value": "Indian",
},
{
"value": "Western",
"label":"Western",
},
{
"value": "Mediterranean",
"label":"Mediterranean",
},
{
"value":"Thai",
"label": "Thai",
},

{
"label":"Japanese",
"value": "Japanese",
},

{
"value":"Korean",
"label": "Korean",
},

{
"label": "Vietnamese",
"value": "Vietnamese",
},

{
"value":"Mexican",
"label": "Mexican",
}
]