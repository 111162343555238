import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "ofc/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "ofc/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { useSelector } from 'react-redux'
import { withRouter } from "react-router-dom";
import { url, prefixImageUrl } from "../../constant";
import SearchBox from "ofc/forms/SearchBox.js";
import GridForRestaurant2 from 'ofc/cards/GridForRestaurants2'

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-4 lg:py-6`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto  justify-center mb-1 mx-2`}
  }
`;
const Card = tw.div`h-full  flex! flex-col sm:border  max-w-sm sm:rounded-md relative focus:outline-none bg-gray-100 `;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-64 sm:h-64 bg-center bg-cover rounded sm:rounded-none sm:rounded-tl-md sm:rounded-tr-md`
]);

const TextInfo = tw.div`sm:px-5 sm:py-2 `;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h6` font-bold `;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-3 h-3 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4 overflow-hidden `;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-1 sm:mt-2 text-xs font-thin`;
const IconWithText = tw.div`flex items-center sm:my-0 text-primary-500 text-xs`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-1 bg-gray-100 text-red-500`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div` text-xs font-semibold text-red-500`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
const ThreeColSlider =  ({ history }) => {  
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
  'infinite': true,
  'autoplay': true,
  'autoplaySpeed': 1300,

    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };


  
  return (
    <Container>
      <Content>
        <HeadingWithControl>
         
          <Controls>
          </Controls>
          
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
           
             <Card  onClick={()=>history.push({pathname: "/restaurants/"})} >
             <CardImage imageSrc="/images/diningout.jpg"  />
             
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           
            </Card> 
              <Card  onClick={()=>history.push({pathname: "/restaurants/"})} >
             
              <img src="/images/hotdeals.jpg" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           
            </Card> 
              <Card  onClick={()=>history.push({pathname: "/restaurants/"})} >
             
              <img src="/images/nightlife.JPG" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           
            </Card> 
        </CardSlider>
      </Content>
    </Container>
  );
};

export default withRouter(ThreeColSlider)